<template>
  <div class="indexPage">
    <!-- 顶部导航栏 -->
    <div class="headNav">
      <!-- 左侧logo -->
      <div class="img-box">
        <img src="../assets/static/home-logo.png" alt="" />
      </div>
      <!-- 右侧功能列表 -->
      <div class="funcs">
        <p style="font-size: 14px">欢迎您! {{ getName == 'null' || getName =='' ? getPhone : getName}}</p>
        <!-- <el-tooltip class="item" content="个人信息">
          <i class="el-icon-user"></i>
        </el-tooltip> -->
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="el-icon-user item"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="reset">修改密码</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-tooltip class="item" content="退出登录">
          <i class="el-icon-switch-button" @click="logout"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 修改密码组件 -->
    <!-- 主体部分 -->
    <div class="body">
      <!-- 左侧导航栏 -->
      <div class="leftNav">
        <!-- 左侧导航栏 -->
        <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
          <el-radio-button v-show="isCollapse" :label="false">
            <span style="font-size:12px !important">展开</span>
            <i class="el-icon-s-fold"></i>
            <i class="el-icon-arrow-right"></i>
          </el-radio-button>
          <el-radio-button v-show="!isCollapse" :label="true">
            <span style="font-size:12px !important">收起</span>
            <i class="el-icon-s-unfold"></i>
            <i class="el-icon-arrow-left"></i>
          </el-radio-button>
        </el-radio-group>
        <el-menu @open="handleOpen" @close="handleClose" :collapse="isCollapse"
          :default-active="activeRouterIndex"
          :default-openeds="openeds"
          class="el-menu-vertical-demo"
          :class="!isCollapse ? 'leftWidth' : ''"
          :unique-opened="true"
        >
          <el-submenu v-for="item in navlist" :key="item.ID" :index="item.Remark">
            <template slot="title">
              <i :class="item.ImageUrl"></i>
              <span>{{item.Name}}</span>
            </template>
            <el-menu-item-group v-for="child in item.children" :key="child.index">
              <el-menu-item
                :index="child.Remark"
                @click="Toclick(child.Path, child.Remark)"
              >{{child.Name}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-user-solid"></i>
              <span>权限管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                index="2-1"
                @click="$router.push()"
              >账号管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->
        </el-menu>
      </div>
      <!-- 右侧渲染路由页面 -->
      <div class="page">
        <div style="padding: 10px 15px 15px">
          <!-- 子页面 -->
          <router-view class="routerView" />
        </div>
      </div>
    </div>
    <ChangePassword :dialog="dialog" />
  </div>
</template>

<script>
import {
  _getRouterList,
  _removeParentRoleId,
  _removeRouterList,
  _removeRoleId,
  _setCurrentRouterPath,
  _getCurrentRouterPath,
  _removeCurrentRouterPath,
} from "@/utils/storage";
import { mapGetters, mapMutations } from "vuex";
import basicMixin from "@/mixins/basic";
import ChangePassword from "@/components/changePassword";
export default {
  mixins: [basicMixin],
  components: {
    ChangePassword
  },
  data() {
    return {
      routerTitleArr: [], //当前页面的路由标题
      navlist: [], // 左侧路由列表
      activeRouterIndex:'', //当前激活的路由
      openeds: ["1"],
      isCollapse: false,
      menuList: [
        // {name: '平台管理', icon: 'el-icon-menu', index: '1', children: [
        //   { index: '1-2', name: '设备管理', path: '/platform/deviceManage/list'},
        //   { index: '1-3', name: '车辆管理', path: '/platform/carManage/list'},
        //   { index: '1-4', name: '轨迹分段', path:'/platform/trajectory'},
        //   { index: '1-5', name: '轨迹匹配',path:'/platform/trajectoryMate'},
        //   { index: '1-6', name: '轨迹查询', path:'/platform/deviceSkim'},
        //   { index: '1-7', name: '车辆监控', path:'/platform/vehicleMonitoring'},
        //   { index: '1-8', name: '电子围栏',path:'/platform/electricFence'},
        // ]},
        // {name: '权限管理', icon: 'el-icon-user-solid', index: '2', children: [
        //   { index: '2-1', name: '角色管理',path: '/power/role'},
        //   { index: '2-2', name: '账号管理',path: '/power/account'},
        //   { index: '2-3', name: '权限分配',path: '/power/jurisdiction'},
        // ]}
      ],
      dialog: {
        visible: false,
      }
    };
  },
  watch: {
    //监听路由变化来更改导航栏信息
    $route(to, from) {
      this.generate();
      //每次进入新页面存到sessionStorage中，刷新页面跳转至这个地址
      _setCurrentRouterPath(to.fullPath);
    },
  },
  computed: {
    ...mapGetters(["getName", "getPhone","getRouter"]),
  },
  methods: {
    ...mapMutations(["setToken", "setInvoiceTaskID", "setFromPath", "setStep"]),

    //生成导航栏信息
    generate() {
      this.routerTitleArr = [];
      let data = {};
      data.path = this.$route.fullPath;
      data.name = this.$route.name;
      this.$route.matched.forEach((item) => {
        if (item.name) {
          this.routerTitleArr.push(item.name);
        }
      });
    },

    //生成菜单
    generateMenu() {
      this.navlist = JSON.parse(localStorage.getItem('routerList'));
      let index = sessionStorage.getItem('activeRouterIndex')
      this.activeRouterIndex = index || this.navlist[0].children[0].Remark
      // this.navlist.push()
    },
    //退出登录
    logout() {
      this.$confirm("确认退出系统吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //退出登录时清空vuex中的部分数据
          this.setToken("");
          this.setInvoiceTaskID("");
          this.setFromPath("");
          this.setStep(0);
          //清空sessionStorage中的数据
          _removeParentRoleId();
          _removeRouterList();
          _removeRoleId();
          _removeCurrentRouterPath();
          this.$router.push("/login");
          sessionStorage.removeItem('activeRouterIndex')
        })
        .catch(() => {});
    },
    //页面跳转的时候设置路由标题
    routerTitleChange(path) {
      //如果点击的页面就是当前页面那就不处理
      if (this.$route.path === path) return;
      //如果点击的是首页
      if (path === "/home") {
        this.$router.push("/home");
        //如果点击的不是首页
      } else {
        this.$router.push(path);
      }
    },
    // 路由跳转
    Toclick(path, activeIndex) {
      sessionStorage.setItem('activeRouterIndex', activeIndex)
      this.$router.push(path)
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleCommand(command) {
      if (command == "reset") {
        this.dialog.visible = true
      }
    },
  },
  created() {
    //生成导航栏信息
    this.generate();
    //生成菜单
    this.generateMenu();
  },
};
</script>

<style scoped lang="scss">
@import "../assets/style/variable.scss";
.indexPage {
  width: 100%;
  box-sizing: border-box;
  min-width: 1000px;
  .headNav {
    height: 60px;
    display: flex;
    align-items: center;
    background-color: $primary;
    overflow: hidden;
    justify-content: space-between;
    .img-box {
      display: flex;
      justify-content: center;
      width: 180px;
    }
    img {
      width: 150px;
      height: 40px;
    }
    .el-menu {
      flex: 1;
    }
    .funcs {
      padding: 0 10px;
      display: flex;
      color: #fff;
      .item {
        color: white;
        font-size: 20px;
        cursor: pointer;
        margin: 0 10px;
      }
    }
    .el-menu-item {
      height: 64px;
      line-height: 64px;
    }
    //顶部导航栏激活样式类
    .is-active {
      background-color: $primaryActive !important;
      color: white !important;
    }
  }
  .body {
    height: calc(100vh - 60px);
    display: flex;
    flex: 1;
    justify-self: start;
    .leftNav {
      // width: 180px;
      height: 100%;
      font-size: 13px !important;
      .el-menu-vertical-demo {
        // height: 100%;
      }
      .leftWidth {
        width: 182px;
      }
      span,
      li {
        font-size: 13px !important;
      }
    }
    .page {
      background-color: #f8f8f8;
      box-sizing: border-box;
      min-width: 1000px;
      width: 100%;
      // overflow: hidden;
      .routerTitle {
        height: 50px;
        line-height: 50px;
        margin-bottom: 10px;
        padding-left: 20px;
        background: #fff;
        box-sizing: border-box;
        .goBack {
          cursor: pointer;
          font-size: 24px;
          margin-right: 20px;
        }
        .el-breadcrumb {
          line-height: 50px !important;
        }
      }
      .routerView {
        // width: calc(100vw - 105px);
        height: 90vh;
        // height: calc(100vh - 145px);
        box-sizing: border-box;
        overflow-y: scroll;
        padding: 10px;
      }
    }
  }
}
</style>
<style>
.el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
.el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px !important;
}
/* 顶部导航样式 */
.indexPage .headNav .el-submenu__icon-arrow {
  color: white !important;
}
.indexPage .headNav .el-submenu__title {
  border: none !important;
}
/* 左侧菜单样式 */
.indexPage .leftNav .el-submenu .el-menu-item {
  min-width: 180px;
}
.indexPage .leftNav .el-menu-item-group__title {
  padding: 0 !important;
}
.indexPage .leftNav .el-menu-item,
.indexPage .leftNav .el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
}
.indexPage .leftNav .el-submenu__title {
  background-color: white;
}
/* 左侧菜单激活子菜单的背景颜色 */
.indexPage .leftNav .el-submenu.is-opened,
.indexPage .leftNav .el-submenu .el-menu {
  background-color: #f5f5f5 !important;
}
/* 左侧菜单宽度 */
.indexPage .leftNav .el-menu-vertical-demo {
  /* width: 180px !important; */
}
.messageBox .el-icon-warning {
  color: #409eff;
  font-size: 20px;
}
.indexPage .leftNav .el-radio-group {
  margin-bottom: 0px !important;
  width: 100%;
}
.indexPage .leftNav .el-radio-group .el-radio-button{
  width: 100%;
}
.indexPage .leftNav .el-radio-group .el-radio-button__inner {
  border: none;
  padding-left: 10px;
  padding-right: 0px;
}
</style>