<template>
  <div class="changePasswordCmpt">
    <el-dialog :visible.sync="dialog.visible" width="620px" :close-on-click-modal="false">
      <span slot="title">修改密码</span>
      <div class="box">
        <div class="title">
          当前登录账号：{{ showNumber }}
        </div>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px">
          <el-form-item prop="loginPass" label="原始密码" required>
            <el-input type="password" v-model="ruleForm.loginPass" autocomplete="off" clearable placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="新密码" required>
            <el-input type="password" v-model="ruleForm.password" autocomplete="off" clearable placeholder="请输入新密码"></el-input>
          </el-form-item>

          <el-form-item prop="checkPass" label="确认密码" required>
            <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" clearable placeholder="请再次输入新密码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetPwd('ruleForm')">确认修改</el-button>
        <el-button @click="closedialog('ruleForm')">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  _getRouterList,
  _removeParentRoleId,
  _removeRouterList,
  _removeRoleId,
  _setCurrentRouterPath,
  _getCurrentRouterPath,
  _removeCurrentRouterPath,
} from "@/utils/storage";
import { resetPassword } from "@/api/register";
import { mapGetters, mapMutations } from "vuex";
import md5 from "js-md5";
export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (!/^[a-zA-Z0-9_-]{6,12}$/.test(value)) {
        callback(new Error("密码须在6-12位数之间"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    let validatePass3 = (rule, value, callback) => {
      if (!/^[a-zA-Z0-9_-]{6,12}$/.test(value)) {
        callback(new Error("密码须在6-12位数之间"));
      } else {
        callback();
      }
    };
    return {
      showNumber: "", // 用于修改密码展示的手机号
      // 修改密码表单验证
      rules: {
        loginPass: [{ validator: validatePass3, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
      ruleForm: {
        name: "",
        loginPass: "",
        password: "",
        checkPass: "",
      }, // 修改密码表单
    }
  },
  props: {
    dialog: {
      typeof: Object,
      default: () => {
        return {visible: false}
      }
    }
  },
  methods: {
    ...mapMutations(["setToken", "setInvoiceTaskID", "setFromPath", "setStep"]),
    // 修改密码
    resetPwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let _this = this;
          const params = {
            name: _this.ruleForm.name,
            loginPass: md5(String(_this.ruleForm.loginPass)),
            password: md5(String(_this.ruleForm.password)),
          }
          resetPassword(params).then(res => {
            //修改成功隐藏弹框
            // this.dialog.visible = false
            _this.$alert("修改成功，请重新登录!", {
              confirmButtonText: '确定',
              callback: action => {
                //退出登录时清空vuex中的部分数据
                this.setToken("");
                this.setInvoiceTaskID("");
                this.setFromPath("");
                this.setStep(0);
                //清空sessionStorage中的数据
                _removeParentRoleId();
                _removeRouterList();
                _removeRoleId();
                _removeCurrentRouterPath();
                this.$router.push("/login");
              }
            })
          })
        }
      });
    },
    // 间隔手机号码
    setPhoneNumber() {
      this.ruleForm.name = this.getPhone;
      this.showNumber = this.getPhone;
    },
    closedialog(formName) {
      this.dialog.visible = false;
      this.$refs[formName].resetFields();
    }
  },
  mounted() {
    this.setPhoneNumber();
  },
  computed: {
    ...mapGetters(["getPhone"]),
  },
}
</script>

<style lang="scss" scoped>
.changePasswordCmpt{
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-dialog__footer{
    border-top: 1px solid #eee;
    text-align: center;
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .btn-send {
    margin-left: 2%;
    text-align: center;
    font-size: 14px;
  }
  .title {
    // margin-bottom: 30px;
    text-align: center;
    padding-bottom: 20px;
    // border-bottom: 1px solid #999999;
  }
}
</style>